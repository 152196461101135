<template>
  <div class="problem-component">
    <loading-indicator v-if="loading" />
    <div v-else-if="rows.length === 0" class="loading-indicator">
      Данных нет
    </div>
    <Vue-good-table :columns="columns" :rows="rows">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'btn'">
          <router-link :to="`/problems/${props.row.id}`">
            <span>
              <button class="table-btn">Подробнее...</button>
            </span>
          </router-link>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </Vue-good-table>
  </div>
</template>

<script>
import loadingIndicator from "@/components/LoadingIndicator.vue";
import { VueGoodTable } from "vue-good-table";

export default {
  props: {
    rows: Array,
    loading: Boolean,
  },
  data() {
    return {
      // ===СТОЛБЦЫ ТАБЛИЦЫ===
      columns: [
        {
          label: "№",
          field: "id",
        },
        {
          label: "Категория",
          field: "category",
        },
        {
          label: "Заявитель",
          field: "plaintiff",
        },
        {
          label: "Номер телефона",
          field: "phone",
          // type: "number",
        },
        {
          label: "Дата поступления",
          field: "createdAt",
        },
        {
          label: "Ответчик",
          field: "defendant",
        },
        {
          label: "Статус",
          field: "status",
        },
        {
          label: "",
          field: "btn",
        },
      ],
    };
  },
  http() {
    return this.$store.state.http;
  },
  components: {
    loadingIndicator,
    VueGoodTable,
  },
};
</script>

<style lang="scss">
@import "vue-good-table/dist/vue-good-table.css";
@import "../../style/global.scss";
@media (max-width: 1600px) {
  .problem-component {
    padding: 0px 50px !important;
  }
}
.empty {
  position: relative;
  top: 74px;
  left: 20px;
  z-index: 10;
}
.vgt-text-disabled {
  color: white;
}
.vgt-table th.sortable button:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  right: 6px;
  top: 50%;
  margin-bottom: -7px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
}
.vgt-table th.sortable button:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  right: 6px;
  top: 50%;
  margin-top: -7px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid white;
}
.vgt-table {
  thead {
    th {
      color: #fff;
      background: $main;
    }
    tr {
      th {
        &:last-child {
          ::after,
          ::before {
            display: none;
          }
        }
      }
    }
  }
}
.vgt-table.bordered th {
  border: none;
}
.problem-component {
  padding: 0px 200px;
  .loading-indicator {
    display: inline-flex;
    position: relative;
    align-items: center;
    position: relative;
    top: 74px;
    left: 10px;
    z-index: 10;
  }
}
.table-btn {
  background-color: $main;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
</style>