import Vue from "vue";
import VueRouter from "vue-router";
import AdminView from "../views/AdminView.vue";
import VueCookies from "vue-cookies-reactive";
import store from "../store";
import axios from "axios";

Vue.use(VueCookies);
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: AdminView,
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminView,
  },

  //===Восстановление пароля===
  {
    path: "/reset",
    name: "reset",
    component: () => import("../views/ForgotPassword.vue"),
  },
  {
    path: "/confirm",
    name: "confirm",
    component: () => import("../views/ConfirmPassword.vue"),
  },
  //===Редактирование пользователя для каждого пользователя===
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/RedactUserView.vue"),
  },
  //===Протоколы роуты===
  {
    path: "/add/problem",
    name: "promlem",
    component: () => import("../views/AddProblemView.vue"),
  },
  {
    path: "/problems",
    name: "promlems",
    component: () => import("../views/ProblemsView.vue"),
  },
  {
    path: "/problems/:id",
    name: "promlemId",
    props: true,
    component: () => import("@/components/problems/ProblemCard.vue"),
  },
  {
    path: "/problem/update/:id",
    name: "promlemUpdateId",
    props: true,
    component: () => import("../views/RedactProblemView.vue"),
  },

  //===Пользователи роуты===
  {
    path: "/users",
    name: "users",
    component: () => import("../views/UsersList.vue"),
  },
  {
    path: "/add/user",
    name: "addUser",
    component: () => import("../views/AddUsersView.vue"),
  },
  //===Категории роуты===
  {
    path: "/add/category",
    name: "addCategory",
    component: () => import("../views/AddCategoryView.vue"),
  },
  {
    path: "/category",
    name: "category",
    component: () => import("../views/CategoryList.vue"),
  },
  //===Логика регистрации===
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  // {
  //   path: "/:notFound(.*)",
  //   name: "notFound",
  //   component: AdminView,
  // },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

//===Ограничение доступа для не зарегестрированного пользователя по токену===
router.beforeEach(async (to, from, next) => {
  //===Логика для восстановления пароля(отправка эмейла)===
  if (to.name === "reset" && to.name !== "login" && !VueCookies.get("token")) {
    next();
  } else if (
    to.name !== "login" &&
    VueCookies.get("token") &&
    !store.state.user.code
  ) {
    //===Логика роутов для Админа===
    axios(store.state.http + "/api/user", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${VueCookies.get("token")}`,
      },
    })
      .then((response) => {
        if (response.data) {
          store.state.user = response.data;
          //===Логика роутов для пользователей===
          if (
            (to.name === "users" || to.name === "addUser") &&
            store.state.user.code === 2
          ) {
            next({ name: "admin" });
          }
          next();
        }
      })
      .catch(() => next({ name: "login" }));
  } else if (to.name !== "login" && !VueCookies.get("token")) {
    next({ name: "login" });
  } else next();
});

export default router;
