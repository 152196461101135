<template>
  <header class="header">
    <div class="header__element">
      <router-link to="/admin">
        <div class="logo">
          <img src="../../assets/svg/logo.svg" alt="" />
        </div>
      </router-link>
      <div class="add-block">
        <img src="../../assets/svg/add.svg" alt="" />
        <img src="../../assets/svg/add-black.svg" alt="" />
        <div class="add-block-link">Добавить</div>
        <div class="add-block-menu">
          <router-link to="/add/problem">
            <button>Проблему</button>
          </router-link>
          <router-link v-if="isAdmin" to="/add/user">
            <button>Пользователей</button>
          </router-link>
          <router-link to="/add/category">
            <button>Категории</button>
          </router-link>
        </div>
      </div>
      <nav>
        <router-link to="/category">Категории</router-link>
        <router-link v-if="isAdmin" to="/users">Пользователи</router-link>
      </nav>
    </div>
    <button class="login-user">
      <div class="login-user-logo"></div>
      <p>{{ userFullName }}</p>
      <ul class="login-user-list">
        <router-link to="/profile">
          <li>Редактировать профиль</li>
        </router-link>
        <li @click="logOut">Выйти</li>
      </ul>
    </button>
  </header>
</template>

<script>
export default {
  methods: {
    logOut() {
      this.$cookies.remove("token");
      this.$store.commit("removeUser");
      this.$router.push({ name: "login" });
    },
  },
  computed: {
    isAdmin() {
      return this.$store.state.user.code === 1;
    },
    userFullName() {
      return `${this.$store.state.user.name} ${this.$store.state.user.surname}`;
    },
  },
};
</script>

<style lang='scss'>
@import "../../style/global.scss";
@media (max-width: 1600px) {
  .header {
    padding: 0 50px !important;
  }
}
nav {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    color: white;
    margin-right: 20px;
    text-decoration: none;
  }
}
.login-user {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: $main;
  min-width: 120px;
  p {
    color: white;
    padding-left: 10px;
  }
  &:hover {
    .login-user-list {
      visibility: visible;
      opacity: 1;
      transition: 0.3s;
      z-index: 200;
      a {
        text-decoration: none;
        color: black;
        li {
          color: black;
          &:hover {
            color: white;
          }
        }
      }
    }
  }
  &-logo {
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: white;
  }
  &-list {
    background-color: white;
    position: absolute;
    top: 40px;
    left: 0px;
    visibility: hidden;
    opacity: 0;
    text-align: start;
    width: 160px;
    box-shadow: 0 30px 60px rgba(53, 53, 121, 0.3);

    li {
      padding: 10px 20px;
      font-size: 14px;

      &:hover {
        background-color: #5897fb;
        color: white;
      }
    }
  }
}
.test-logo {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  background-color: black;
  border-radius: 50%;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: white;
  background-color: $main;
  padding: 0 200px;

  &__element {
    display: flex;
  }
  .add-block {
    img {
      visibility: hidden;
      opacity: 0;
      padding: 5px;
      &:nth-child(1) {
        position: absolute;
        visibility: visible;
        opacity: 1;
      }
    }

    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 70px;
    position: relative;
    cursor: pointer;
    &:hover {
      background-color: #fff;
      color: black;
      transition: 0.3s;
      img {
        visibility: visible;
        opacity: 1;
        &:nth-child(1) {
          position: absolute;
          visibility: hidden;
          opacity: 0;
        }
      }
      .add-block-menu {
        opacity: 1;
        background-color: #fff;
        visibility: visible;
        transform: translateY(29px);
        transition: 0.2s;
      }
    }
    &-link {
      padding: 5px;
    }
    &-menu {
      background-color: $btn-bg-color;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 31px;
      left: 0;
      width: 150px;
      box-shadow: $drop-menu-shadow;
      z-index: 20;
      button {
        display: inline-block;
        width: 100%;
        text-align: start;
        border: none;
        background-color: white;
        font-size: 15px;
        cursor: pointer;
        padding: 16px;
        transition: 0.2s;
        &:hover {
          background-color: $btn-bg-color;
          color: white;
        }
      }
    }
  }
  .logo {
    cursor: pointer;
    width: 60px;
    height: 60px;
    padding: 7px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>