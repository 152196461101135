<template>
  <div class="loading-indicator">
    <span>{{ title }}</span>
    <div class="loading-indicator__dots">
      <span v-for="i in dots" :key="i">.</span>
    </div>
  </div>
</template>

<script>
/**
 * Текст "Подождите" с динамическими точками
 */
export default {
  name: "LoadingIndicator",
  props: {
    title: {
      type: String,
      default() {
        return "Подождите, идет загрузка";
      },
    },
  },
  data() {
    return {
      dots: 0,
      interval: null,
    };
  },
  created() {
    this.interval = setInterval(() => {
      if (this.dots >= 3) {
        this.dots = 0;
      } else {
        this.dots += 1;
      }
    }, 100);
  },
};
</script>

<style lang="scss">
.loading-indicator {
  display: inline-flex;
  align-items: center;
  position: relative;

  &__dots {
    position: absolute;
    right: -3px;
    transform: translateX(100%);
  }
}
</style>
