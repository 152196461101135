<template>
  <div class="admin-page">
    <Header-Component></Header-Component>
    <Filters-Component @filtered-data="filtered"></Filters-Component>
    <Problems-Component :loading="loading" :rows="rows"></Problems-Component>
  </div>
</template>

<script>
import FiltersComponent from "@/components/filters/filtersComponent.vue";
import ProblemsComponent from "@/components/problems/ProblemsComponent.vue";
import HeaderComponent from "@/components/header/HeaderComponent.vue";
import axios from "axios";

export default {
  created() {
    axios({
      url: this.http + "/api/problems",
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.$cookies.get("token")}`,
      },
    }).then(({ data }) => {
      this.rows = data.map((item) => {
        const [plaintiff] = item.plaintiffs;
        const [defendant] = item.defendants;

        return {
          id: item.id,
          category: item.category?.title || "Пусто",
          plaintiff:
            plaintiff?.name === undefined
              ? "Пусто"
              : `${plaintiff?.surname} ${plaintiff?.name} ${plaintiff?.patronymic}`,
          phone: plaintiff?.phone || "Пусто",
          createdAt: item.receipt_date?.split(" ")[0] || "Пусто",
          defendant:
            defendant?.name === undefined
              ? "Пусто"
              : `${defendant?.surname} ${defendant?.name} ${defendant?.patronymic}`,
          status: item.status,
        };
      });
      this.loading = false;
    });
  },
  computed: {
    http() {
      return this.$store.state.http;
    },
  },
  methods: {
    filtered({ data }) {
      this.rows = data.map((item) => {
        const [plaintiff] = item.plaintiffs;
        const [defendant] = item.defendants;

        return {
          id: item.id,
          category: item.category?.title || "Пусто",
          plaintiff:
            plaintiff?.name === undefined
              ? "Пусто"
              : `${plaintiff?.surname} ${plaintiff?.name} ${plaintiff?.patronymic}`,
          phone: plaintiff?.phone || "Пусто",
          createdAt: item.receipt_date?.split(" ")[0] || "Пусто",
          defendant:
            defendant?.name === undefined
              ? "Пусто"
              : `${defendant?.surname} ${defendant?.name} ${defendant?.patronymic}`,
          status: item.status,
        };
      });
    },
  },
  data() {
    return {
      rows: [],
      loading: true,
    };
  },
  components: {
    FiltersComponent,
    ProblemsComponent,
    HeaderComponent,
  },
};
</script>

<style lang='scss'>
.admin-page {
}
</style>