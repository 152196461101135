import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    http: "https://sokol.comrades.dev",
    // "https://sokol.comrades.dev""http://projectfet"
  },
  getters: {},
  mutations: {
    removeUser(state) {
      // изменяем состояние
      state.user = {};
    },
  },
  actions: {},
  modules: {},
});
