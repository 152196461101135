<template>
  <div class="filter-components">
    <!-- <div class="filter-components-block">
      <div class="input-component">
        <div class="input-lupa">
          <img src="../../assets/svg/lupa.svg" alt="" />
        </div>
        <input type="text" placeholder="Поиск" />
      </div>
    </div> -->
    <div class="filter-components-block">
      <v-select
        class="select-category"
        placeholder="Статус"
        :options="statuses"
        v-model="status"
      >
        <div slot="no-options">Нет добавленных статусов</div></v-select
      >
      <v-select
        class="select-status"
        placeholder="Категория"
        :options="categories"
        v-model="category"
      >
        <div slot="no-options">Нет добавленных категорий</div></v-select
      >
      <v-select
        class="select-status"
        placeholder="Сотрудники"
        :options="formattedUsers"
        v-model="user"
      >
        <div slot="no-options">Нет добавленных сотрудников</div></v-select
      >
      <date-picker
        valueType="format"
        class="date-picker"
        placeholder="Дата"
        v-model="time"
        range
      ></date-picker>
      <button
        @click="filtered"
        class="filters-btn"
        :class="{ 'filters-btn__load': loading }"
      >
        <LoadingIndicator v-if="loading" title="Загрузка" />
        <span v-else>Отправить</span>
      </button>
    </div>
  </div>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import axios from "axios";
import DatePicker from "vue2-datepicker";

export default {
  async created() {
    const responseUsers = await axios({
      url: this.http + "/api/users",
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.$cookies.get("token")}`,
      },
    });
    responseUsers.data.forEach((user) => {
      const userToArray = `${user.surname} ${user.name}`;
      this.users.push(user);
      this.usersId.push(user.id);
      this.formattedUsers.push(userToArray);
    });
    const response = await axios({
      url: this.http + "/api/category",
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.$cookies.get("token")}`,
      },
    });
    response.data.forEach((element) => {
      let category = {
        id: element.id,
        title: element.title,
      };

      this.categories2.push(category);
      this.categories.push(element.title);
    });
  },
  data() {
    return {
      loading: false,
      categories2: [],
      categories: [],
      user: "",
      category: "",
      statuses: ["Не решено", "Решено"],
      status: "",
      time: [],
      formattedUsers: [],
      usersId: [],
      users: [],
    };
  },
  methods: {
    async filtered() {
      console.log(this.time);
      if (this.time[0] === null && this.time[1] === null) {
        return;
      }
      this.loading = true;
      let category_id = this.categories2.find(
        (item) => item.title === this.category
      )?.id;
      let user_index = this.formattedUsers.findIndex(
        (user) => user === this.user
      );
      let user_id = this.users[user_index]?.id;

      axios({
        url: this.http + "/api/extradite",
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.$cookies.get("token")}`,
        },
        params: {
          category: category_id,
          user_id: user_id,
          status: this.status?.toLowerCase(),
          receipt_date: this.time,
        },
      }).then((response) => {
        this.$emit("filtered-data", response);
        this.loading = false;
      });
    },
  },
  computed: {
    http() {
      return this.$store.state.http;
    },
  },
  components: {
    DatePicker,
    LoadingIndicator,
  },
};
</script>

<style lang="scss">
@import "vue2-datepicker/index.css";
@import "../../style/global.scss";

@media (max-width: 1600px) {
  .filter-components {
    padding: 50px 50px 20px 50px !important;
  }
}
.select-category,
.select-status {
  width: 350px;
  margin-right: 10px;
}

.filter-components {
  padding: 50px 200px 20px 200px;
  .filters-btn {
    margin-left: 5px;
    padding: 8px;
    border: none;
    border-radius: 5px;
    background-color: $btn-color;
    font-size: 16px;
    color: white;
    cursor: pointer;
    &__load {
      padding: 8px 20px;
    }
  }
}
.filter-components-block {
  display: flex;
  justify-content: space-between;
  .input-component {
    display: flex;
    position: relative;
    width: 100%;

    .input-lupa {
      position: absolute;
      top: 17%;
      left: 0;
      width: 25px;
      height: 25px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    input {
      display: block;
      width: 100%;
      height: 34px;
      padding-left: 35px;
      font-size: 20px;
      outline: none;
      border: none;
      // border-radius: 50px;
      border-bottom: 2px solid $btn-color;
      &::-webkit-input-placeholder {
        opacity: 1;
        font-size: 18px;
        transition: opacity 0.3s ease;
      }
      &:focus {
        transition: opacity 0.3s ease;

        &::-webkit-input-placeholder {
          opacity: 0;
          transition: opacity 0.3s ease;
        }
      }

      // box-shadow: 0 5px 50px green, 0 0 0 10px #ffffffeb;
    }
  }
  // justify-content: space-between;
  // padding: 50px 200px 20px 200px;
}
</style>