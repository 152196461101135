import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vSelect from "vue-select";
import VueCookies from "vue-cookies-reactive";
import Notifications from "vue-notification";

Vue.config.productionTip = false;

Vue.use(Notifications);
Vue.use(VueCookies);

Vue.$cookies.set("theme", "default");
Vue.$cookies.set("hover-time", "1s");

Vue.component("v-select", vSelect);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
