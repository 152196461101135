<template>
  <div id="app">
    <router-view />
    <notifications position="bottom right" :max="3" />
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss">
@import "./style/global.scss";
@import "vue-select/dist/vue-select.css";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
}
::-webkit-scrollbar {
  width: 10px; /* ширина для вертикального скролла */
  background-color: white;
}
::-webkit-scrollbar-thumb {
  background-color: $main;
}
#app {
  width: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
